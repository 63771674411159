export const EVENTS = {
  // Account settings
  ADD_USER_BUTTON_CLICKED: 'add_user_button_clicked',
  LEARN_MORE_SUBSCRIPTION_CLICKED: 'learn_more_subscription_clicked',
  OTHER_PMS_SELECTED: 'other_pms_selected',
  REMOVE_USER_BUTTON_CLICKED: 'remove_user_button_clicked',
  SAVE_PROFILE_CHANGES_BUTTON_CLICKED: 'save_profile_changes_button_clicked',
  SAVE_USER_PREFERENCES_BUTTON_CLICKED: 'save_user_preferences_button_clicked',
  SEND_INVITE_TO_NEW_USER_BUTTON_CLICKED: 'send_invite_to_new_user_button_clicked',
  TRIGGER_WELCOME_EMAIL: 'trigger_welcome_email',
  TRIGGER_PASSWORD_RESET_EMAIL: 'trigger_password_reset_email',
  SEND_INVITE_TO_NEW_USER_ERROR: 'send_invite_to_new_user_error',

  // App wide
  GENERIC_ERROR: 'generic_error',
  AUTHNZ_ERROR: 'authnz_error',
  CREATE_ORDER_BUTTON_CLICKED: 'create_order_button_clicked',
  LAB_CHANGED: 'lab_changed',
  OFFICE_CHANGED: 'office_changed',
  SIDE_NAV_BUTTON_CLICKED: 'side_nav_button_clicked',
  SIDE_NAV_LINK_CLICKED: 'side_nav_link_clicked',
  INTERCOM_MODAL_OPENED: 'intercom_modal_opened',
  SPEC_ORDER_POPOVER_LEARN_MORE_CLICKED: 'spec_order_popover_learn_more_clicked',
  WANT_PMS_INTEGRATION_MODAL_OPENED: 'want_pms_integration_modal_opened',
  HELP_DROPDOWN_OPENED: 'help_dropdown_opened',

  // Drafts
  DRAFT_ORDER_CLICKED: 'draft_order_clicked',
  DELETE_DRAFT_BUTTON_CLICKED: 'delete_draft_button_clicked',

  // Lab Pay
  MAKE_PAYMENT_MODAL_OPENED: 'make_payment_modal_opened',
  PAYWALL_MODAL_TRIGGERED: 'paywall_modal_triggered',
  PAYMENT_METHOD_REQUIRED_MODAL_TRIGGERED: 'payment_method_required_modal_triggered',

  // Order creation
  BAD_ORDER_METADATA_FOUND: 'BAD_ORDER_METADATA_FOUND',
  BAD_ORDER_CONTEXT: 'BAD_ORDER_CONTEXT',
  BAD_ORDER_OPTIONS_ID_LOOKUP: 'BAD_ORDER_OPTIONS_ID_LOOKUP',
  CANCEL_ORDER_BUTTON_CLICKED: 'cancel_order_button_clicked',
  CONFIRM_CANCEL_ORDER_BUTTON_CLICKED: 'confirm_cancel_order_clicked',
  CATALOG_COMPATABILITY_CHECK_FAILED: 'catalog_compatability_check_failed',
  CATALOG_SUGGESTION_CLICKED: 'catalog_suggestion_clicked',
  EDITABLE_ORDER_OPTIONS_MASSAGING_FAILED: 'editable_order_options_massaging_failed',
  BASE_LENS_OPTIONS_CALL_FAILED: 'base_lens_options_call_failed',
  MAIL_TO_PATIENT_ENABLED: 'mail_to_patient_enabled',
  MORE_OPTIONS_BUTTON_CLICKED: 'more_options_button_clicked',

  FRAMES_DATA_MODAL_OPENED: 'frames_data_modal_opened',
  FRAMES_DATA_BAD_FILTER: 'frames_data_bad_filter',

  NL_VOUCHER_ERRORED: 'nl_voucher_errored',
  LAB_POLICY_CHECK_ERRORED: 'lab_policy_check_errored',
  LAB_POLICY_CHECK_FAILED: 'lab_policy_check_failed',

  SAVE_DRAFT_BUTTON_CLICKED: 'save_draft_button_clicked',
  TRACE_FILE_MIMETYPE_ADDED: 'trace_file_mimetype_added',
  SCANNED_ORDER_FILE_MIMETYPE_ADDED: 'scanned_order_file_mimetype_added',

  // Order details
  DUPLICATE_ORDER_BUTTON_CLICKED: 'duplicate_order_button_clicked',
  ESSENTIALS_PAYWALL_TRIGGERED: 'essentials_paywall_triggered',
  INVOICE_ID_COPIED: 'invoice_id_copied',
  NOTIFY_PATIENT_BUTTON_CLICKED: 'notify_patient_button_clicked',
  ORDER_NOTIFICATION_SENT: 'order_notification_sent',
  PRINT_PACKING_SLIP_BUTTON_CLICKED: 'print_packing_slip_button_clicked',
  PRINT_ORDER_DETAILS_BUTTON_CLICKED: 'print_order_details_button_clicked',
  RX_NUMBER_COPIED: 'rx_number_copied',
  REDO_ORDER_BUTTON_CLICKED: 'redo_order_button_clicked',
  TRAY_NUMBER_COPIED: 'tray_number_copied',
  VIEW_PACKING_SLIP_BUTTON_CLICKED: 'view_packing_slip_button_clicked',
  VIEW_ORDER_INVOICE_CLICKED: 'view_order_invoice_clicked',

  // Orders table
  REFRESH_BUTTON_CLICKED: 'refresh_button_clicked',
  SEARCH_FILTER_CLICKED: 'search_filter_clicked',
  SEARCH_FILTER_CLEARED: 'search_filter_cleared',
  SEARCH_FILTER_SUBMITTED: 'search_filter_submitted',
  ORDER_ROW_CLICKED: 'order_row_clicked',
  ORDER_SEARCH_TERM_SUBMITTED: 'order_search_term_submitted',
  ORDER_SCROLL_CURSOR_SET: 'order_scroll_cursor_set',

  // Lab view events
  // Payments
  ONE_TIME_PAYMENT_MODAL_OPENED: 'one_time_payment_modal_opened',
  LAB_VIEW_ORDER_CREATION_OPENED: 'lab_view_order_creation_opened',

  // Interactions
  ORDER_CREATION_KEYBOARD_SHORTCUT_USED: 'order_creation_keyboard_shortcut_used',
  SIDEBAR_COLLAPSED: 'sidebar_collapsed',
  SIDEBAR_EXPANDED: 'sidebar_expanded',
};
