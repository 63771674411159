import React, { MouseEventHandler } from 'react';
import MenuBurgerIcon from '@assets/icons/MenuBurger-40-White.svg';
import { SidebarDisplayMode } from '../types';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@shared/react_components/Tooltip';
import Icon from '@shared/react_components/Icon';

interface Props {
  sidebarDisplayMode: SidebarDisplayMode;
  onMenuButtonClick: MouseEventHandler<HTMLButtonElement>;
}

export default function MenuButton({ sidebarDisplayMode, onMenuButtonClick }: Props) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className="tw-w-8 tw-h-8 md:tw-w-10 md:tw-h-10 tw-flex tw-items-center tw-justify-center tw-p-0 tw-appearance-none tw-bg-transparent tw-rounded-lg tw-transition hover:tw-bg-primary-600 active:tw-opacity-80 tw-border-none tw-shadow-none"
          onClick={onMenuButtonClick}
        >
          <Icon name="MenuBurger" variant="White" size={40} />
        </button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent side="bottom" sideOffset={10}>
          Click to {sidebarDisplayMode === 'expanded' ? 'collapse' : 'expand'} the sidebar
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}
