import React from 'react';
import { usePermissions } from '@shared/utils/user';
import { Link } from './types';

import HomeIcon from '@assets/icons/Home-24-CurrentColor.svg';
import DollarIcon from '@assets/icons/Dollar-24-CurrentColor.svg';
import ReportChartIcon from '@assets/icons/ReportChart-24-CurrentColor.svg';
import SettingIcon from '@assets/icons/Setting-24-CurrentColor.svg';

const routeMap = {
  ECPS: 'ecps',
  PAYMENTS: 'payments',
  REPORTS: 'reports',
  SETTINGS: 'settings',
};

const prefix = '^/lab/[^/]+/';
const matchAny = '(/.*)?$';

const links: Required<Omit<Link, 'onClick'>>[] = [
  {
    href: routeMap.ECPS,
    label: 'Home',
    icon: <HomeIcon />,
    matchPattern: new RegExp(prefix + routeMap.ECPS + matchAny),
  },
  {
    href: routeMap.PAYMENTS,
    label: 'Payments',
    icon: <DollarIcon />,
    matchPattern: new RegExp(prefix + routeMap.PAYMENTS + matchAny),
  },
  {
    href: routeMap.REPORTS,
    label: 'Reports',
    icon: <ReportChartIcon />,
    matchPattern: new RegExp(prefix + routeMap.REPORTS + matchAny),
  },
  {
    href: routeMap.SETTINGS,
    label: 'Settings',
    icon: <SettingIcon />,
    matchPattern: new RegExp(prefix + routeMap.SETTINGS + matchAny),
  },
];

export default function LabLinks() {
  const hasReportsPemission = usePermissions({ requiredPermissions: ['lab:read:report'] });
  const hasPaymentPermission = usePermissions({ requiredPermissions: ['lab:read:payments'] });

  const routeToEnabledMap = {
    [routeMap.ECPS]: true,
    [routeMap.PAYMENTS]: hasPaymentPermission,
    [routeMap.REPORTS]: hasReportsPemission,
    [routeMap.SETTINGS]: true,
  };

  return links.filter((link) => routeToEnabledMap[link.href]);
}
