export function getUrlWithHostAndProtocol() {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  return `${protocol}//${hostname}`;
}

export function isLocalhost(url: string) {
  return url.match(/^https?:\/\/127\.0\.0\.1$/) || url.match(/^https?:\/\/localhost$/);
}

export function convertObjectToSearchParams(obj: Record<string, string | boolean | undefined | string[]>): URLSearchParams {
  const searchParams = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => searchParams.append(key, value));
    } else if (value !== undefined) {
      searchParams.append(key, `${value}`);
    }
  });

  return searchParams;
}
