import { ILab } from '@core/state/user-metadata/user-metadata.state';
import { create } from 'zustand';

type LabState = {
  lab: ILab;
};

type StoreShape = LabState & {
  setActiveLab: (lab: ILab) => void;
};

const initialState: LabState = {
  lab: {
    lab_id: '',
    lab_name: '',
    address: {
      line1: '',
      line2: null,
      city: '',
      state: '',
      country: '',
      postal_code: '',
    },
    qb_realm_id: '',
    lab_pay_preferences: {
      partial_payments_enabled: false,
      card_processing_fee_rate: 0,
    },
    permissions: [],
  },
};

const useLabStore = create<StoreShape>((set) => ({
  ...initialState,
  setActiveLab: (lab) =>
    set(() => ({
      lab,
    })),
}));

export default useLabStore;
