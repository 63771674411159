import { EVENTS } from '@shared/constants/events';
import useIntercom from '@shared/hooks/useIntercom';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import posthog from 'posthog-js';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { Userpilot } from 'userpilot';
import EcpLinks from './EcpLinks';
import LabLinks from './LabLinks';
import Separator from './Separator';
import SidebarLinkGroup from './SidebarLinkGroup';

import InfoIcon from '@assets/icons/InformationSquare-24-CurrentColor.svg';
import ChatIcon from '@assets/icons/MessagesChat-24-CurrentColor.svg';
import CreateOrderButton from './Buttons/CreateOrderButton';
import UpgradeToProButton from './Buttons/UpgradeToProButton';
import { useFeatureFlagEnabled } from '@shared/hooks/useFeatureFlagEnabled';
import useOfficeLabStore from '@shared/state/office-lab-store';

interface Props {
  displayMode: 'expanded' | 'collapsed';
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isMobile?: boolean;
}

export default function Sidebar({ displayMode, setSidebarOpen, isMobile }: Props) {
  const { isAvailable } = useIntercom();
  const isSpecCheckProEnabled = useFeatureFlagEnabled('spec-check-pro-enabled');
  const { office } = useOfficeLabStore();
  const isFreeUser = office.sub_tier === 'free';

  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null within SideNav');

  const isLabUser = user.user_type === 'labUser';
  const isEcpUser = user.user_type === 'ecpUser';

  const links = isLabUser ? LabLinks() : EcpLinks();

  return (
    <aside
      style={{ width: isMobile ? '100%' : displayMode === 'expanded' ? '13.5rem' : '5rem', transition: '0.2s ease-in-out' }}
      className="tw-h-full tw-p-4 tw-bg-white !tw-border-y-0 !tw-border-l-0 !tw-border-r !tw-border-solid !tw-border-r-neutral-200 tw-flex tw-items-center tw-justify-between tw-flex-col tw-gap-4 tw-transition tw-overflow-y-auto tw-overflow-x-hidden print:tw-hidden"
    >
      <div className="tw-w-full tw-flex tw-items-center tw-justify-start tw-flex-col tw-gap-4">
        <SidebarLinkGroup groupId="top" links={links} displayMode={displayMode} setSidebarOpen={setSidebarOpen} />

        {isEcpUser && (
          <>
            <Separator />

            <CreateOrderButton displayMode={displayMode} setSidebarOpen={setSidebarOpen} />

            {isSpecCheckProEnabled && isFreeUser && <UpgradeToProButton displayMode={displayMode} setSidebarOpen={setSidebarOpen} />}
          </>
        )}
      </div>

      <div className="tw-w-full tw-flex tw-items-center tw-justify-start tw-flex-col">
        <Separator className="tw-mb-4" />
        <SidebarLinkGroup
          groupId="bottom"
          links={[
            ...(isEcpUser
              ? [
                  {
                    label: 'Lab Live Chat',
                    icon: <ChatIcon />,
                    onClick: () => {
                      if (isAvailable) {
                        window.Intercom('show');

                        posthog.capture(EVENTS.INTERCOM_MODAL_OPENED, {
                          source: 'side-bar',
                        });
                      }
                    },
                  },
                ]
              : []),
            {
              label: 'Help',
              icon: <InfoIcon />,
              onClick: () => {
                Userpilot.trigger('resource_center:7Af0tTBnTM');
              },
            },
          ]}
          displayMode={displayMode}
          setSidebarOpen={setSidebarOpen}
        />
      </div>
    </aside>
  );
}
