import { useFeatureFlagEnabled as usePostHogFeatureFlagEnabled } from 'posthog-js/react';

type Flags =
  | 'ocr-enabled'
  | 'pos-enabled'
  | 'pos-terminal-enabled'
  | 'mtp-disabled'
  | 'order-notes-enabled'
  | 'orders-overview-v2-enabled'
  | 'spec-check-pro-enabled';

export function useFeatureFlagEnabled(flag: Flags): boolean | undefined {
  return usePostHogFeatureFlagEnabled(flag);
}
